import React from 'react'
import { toast } from 'react-toastify';
import logo from '../Assets/images/MWW-Logo.png'
import { Image } from 'antd';

const Toast = (type, message, position, time) => {
    toast.dismiss();
    return (
        <>
            {type == 'error' ?
                toast.error(message, {
                    position: position ? position : "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                : type == 'success' ?
                    toast.success(message, {
                        position: position ? position : "top-right",
                        autoClose: time ? time: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    : type == 'warn' ?
                        toast.warn(message, {
                            position: position ? position : "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        :
                        toast(message, {
                            position: position ? position : "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
            }
        </>
    )
}

export default Toast

/* 
    ******** Positions ********  
    #"top-left"
    #"top-right"
    #"top-center"
    #"bottom-left"
    #"bottom-right"
    #"bottom-center"

    ******** Types ******** 
    #success
    #error
    #warn
    #default
*/