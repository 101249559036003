import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBuhYtj43W9kVfah8zjtWDaKo6QS_Mzt70",
    authDomain: "mww-on-demand-f255b.firebaseapp.com",
    projectId: "mww-on-demand-f255b",
    storageBucket: "mww-on-demand-f255b.appspot.com",
    messagingSenderId: "219208227936",
    appId: "1:219208227936:web:17a87f0bccbd7e4984f705"
};

const app = initializeApp(firebaseConfig);

let messaging = null
if (navigator.vendor !== 'Apple Computer, Inc.') {
    messaging = new getMessaging(app);
} else {
    console.log("Running on Safari")
}

/* RESPONSOBLE FOR FIRING NOTIFICATION */
export const onMessageListener = (navRec) => {
    new Promise((resolve, reject) => {
        onMessage(messaging, (payload) => {
            navRec(payload);
            resolve(payload);
        });
    });
}

export default messaging;
export { app };