import { Button, Watermark } from 'antd';
import React, { useEffect, useState } from 'react';
import logo from './Assets/images/MWW-Logo.png';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const errorHandler = (error, info) => {
            // Log the error to an error tracking service if needed
            console.error('Error:', error, info);
            setHasError(true);
        };

        // Register error handler
        window.addEventListener('error', errorHandler);

        return () => {
            // Unregister error handler when component unmounts
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    return hasError ? (
        <Watermark style={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }} height={30} width={250} image={logo}>
            <div className='text-center rounded bg-danger p-5' style={{opacity:0.8}}>
                <h2 className='text-white'>Something went wrong.</h2>
                <Button className='bg-primary text-white' type='text' onClick={() => window.location.reload()}>Reload</Button>
            </div>
        </Watermark>
    ) : (
        children
    );
};

export default ErrorBoundary;
