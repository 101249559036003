import { Image, Modal } from 'antd';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import languages from '../Constant/languages';
import noNetIcon from '../Assets/noNet.png';

const NetConnection = ({ netModal, setNetModal }) => {
    const lang = useSelector((state) => state?.common?.activeLang);
    const activeNet = useSelector((state) => state?.common?.activeNet);
    useEffect(() => {
        if (activeNet) {
            setNetModal(setNetModal)
        } else {
            setNetModal(!setNetModal)
        }
    }, []);
    return (
        <div>
            <Modal centered width={800} open={netModal} footer={null} onCancel={() => setNetModal(!netModal)} >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Image id="non-selectable" height={350} width={350} src={noNetIcon} preview={false} />
                    <h2 className="text-danger" ><b><i>{languages[lang]?.common__content?.internet__lost ?? 'Internet Connectivity Lost'}... <span className="text-primary">{languages[lang]?.common__content?.please__check ?? 'Please check!'}</span></i></b></h2>
                </div>
            </Modal>
        </div>
    )
}

export default NetConnection