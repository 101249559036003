export const LOGIN = 'LOGIN';
export const ADMINCAT = 'ADMINCAT';
export const JWT_TOKEN = 'JWT_TOKEN';
export const CSRF_TOKEN = 'CSRF_TOKEN';
export const NET__CONNECT = 'NET__CONNECT';
export const ADD_PROD_DATA = 'ADD_PROD_DATA';
export const ADMIN_SUB_CAT = 'ADMIN_SUB_CAT';
export const GET_GALL_DATA = 'GET_GALL_DATA';
export const ADMIN_PROD_LIST = 'ADMIN_PROD_LIST';
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER';
export const CHANGE_ACTIVE_LANG = 'CHANGE_ACTIVE_LANG';
export const SET_TEXTURE_POSITION = 'SET_TEXTURE_POSITION';
