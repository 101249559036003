import React from 'react';
import { Image } from 'antd';
import logo from '../Assets/images/MWW-Logo.png';
import languages from '../Constant/languages';
import { useSelector } from 'react-redux';

const CustomLoader = () => {
    const lang = useSelector((state) => state?.common?.activeLang);
    return (
        <div style={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
            <div>
                <Image preview={false} src={logo} height={40} width={400} />
                <h2 className='mt-4' style={{ textAlign: 'center' }}>{languages[lang]?.common__content?.loading ?? "Loading"}... <span style={{ color: '#de2928' }}>{languages[lang]?.common__content?.wait ?? "Please wait!"}</span></h2>
            </div>
        </div>
    )
}

export default CustomLoader