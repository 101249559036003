import { LOGIN, ADMINCAT, ADMIN_SUB_CAT, ADMIN_PROD_LIST, ADD_PROD_DATA, CHANGE_ACTIVE_LANG, NET__CONNECT, GET_GALL_DATA, GET_ALL_CUSTOMER, SET_TEXTURE_POSITION, CSRF_TOKEN, JWT_TOKEN } from "../types";

let lngg = localStorage.getItem("langType")
const INITIAL_STATE = {
    activeNet: true,
    csrfToken: null,
    jwtToken: null,
    admin_cat: null,
    login_user: null,
    allCustomer: null,
    gallery_list: null,
    admin_sub_cat: null,
    admin_prod_list: null,
    added_prod_data: null,
    activeLang: lngg ?? "english",
    texturePosition: { x: 0, y: 0 },
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case NET__CONNECT:
            return {
                ...state,
                activeNet: payload,
            };
        case CSRF_TOKEN:
            return {
                ...state,
                csrfToken: payload,
            };
        case JWT_TOKEN:
            return {
                ...state,
                jwtToken: payload,
            };
        case CHANGE_ACTIVE_LANG:
            return {
                ...state,
                activeLang: payload,
            };
        case LOGIN:
            if (payload) {
                return {
                    ...state,
                    login_user: payload,
                };
            } else {
                return state;
            }
        case ADMINCAT:
            if (payload?.status) {
                return {
                    ...state,
                    admin_cat: payload?.data,
                };
            } else {
                return { admin_cat: null };
            }
        case ADMIN_SUB_CAT:
            if (payload?.status) {
                return {
                    ...state,
                    admin_sub_cat: payload?.data?.[0],
                };
            } else {
                return state;
            }
        case ADMIN_PROD_LIST:
            if (payload?.status) {
                return {
                    ...state,
                    admin_prod_list: payload?.data,
                };
            } else {
                return state;
            }
        case ADD_PROD_DATA:
            if (payload?.status) {
                return {
                    ...state,
                    added_prod_data: payload?.data,
                };
            } else {
                return state;
            }
        case GET_ALL_CUSTOMER:
            if (payload?.status) {
                return {
                    ...state,
                    allCustomer: payload?.data,
                };
            } else {
                return state;
            }
        case GET_GALL_DATA:
            if (payload?.status) {
                return {
                    ...state,
                    gallery_list: payload?.data,
                };
            } else {
                return state;
            }
        case 'SET_TEXTURE_POSITION':
            return {
                ...state,
                texturePosition: action.payload,
            };
        default:
            return state;
    }
};
