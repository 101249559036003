const languages = {
    english: {
        auth: {
            online: "online",
            sign__in: "Sign in",
            change__pass: "Change password",
            reset__password: "Reset Password",
            forget__password: "Forget Password",
            not_account: "Don't have an account?",
            create__account: "Create your account",
            create__new__account: "Create new account",
            already__account: "Already have an account?",
            image__title: "Create and sell custom products",
            sign__terms: "I understand that MWW On Demand is a business-to-business service, and I agree to the",
            reset__title: "Enter your email address. We'll send an email with instructions to reset your password.",
        },
        auth__header: {
            home: "Home",
            blog: "Company blog",
            what__make: "What we make",
            how__works: "How it works",
        },
        lables: {
            email: "Email",
            access: "Access",
            password: "Password",
            lastName: "Last Name",
            firstName: "First Name",
            old__password: "Old Password",
            new__password: "New Password",
            conf__password: "Confirm Password",
            password__conf: "Password confirmation",
            company_name: "Company name",
            contact_name: "Contact name",
            add_line1: "Address line 1",
            add_line2: "Address line 2",
            country: "Country",
            state: "State",
            city: "City",
            zip_Code: "Zip code",
            company_email: "Company email",
            contact_phone: "Contact phone",
            tax_id: "Tax ID",
            tax_reg_num: "NC sales and tax registration number",
            card_name: "Name on card",
            card_num: "Card number",
            ex_month: "Expiration month",
            ex_year: "Expiration year",
            Paytrace_id: "Paytrace id",
            or: "OR",
            gender: "Gender",
            height: "Height (Inch)",
            weight: "Weight (LBS)",
            age: "Age",
            waist: "Waist",
            ethnicity: "Ethnicity",
            neck: "Neck",
        },
        placeholders: {
            email: "Email",
            password: "Enter Password",
            lastName: "Enter Last Name",
            firstName: "Enter First Name",
            password__conf: "Password confirmation",
            company_name: "Enter Company name",
            contact_name: "Enter Contact name",
            add_line1: "Enter Address line 1",
            add_line2: "Enter Address line 2 (Optional)",
            zip: "Enter Zip code",
            contact_phone: "Enter Contact phone",
            tax_id: "Enter Tax ID",
            optional: "Optional",
            enterValue: "Enter Value",
            enterProcess: "Enter the Process",
            selectCategory: "Select Category",
            enterFeatures: "Enter the Features",
            selectSubcategory: "Select Subcategory",
            enterDescription: "Enter the Description",
            enterProdTime: "Enter the Production Time",
            enterConstruction: "Enter the Construction",
            enterCareInst: "Enter the care Instructions",
            selectProdVarients: "Enter the Product Varients",
            tax_reg_num: "Enter tax registration number",
            ex_month: "Enter Expiration month",
            ex_year: "Enter Expiration year",
            select_country: "Select Country",
            select_gender: "Select Gender",
            select_state: "Select State",
            Paytrace_id: "Enter Paytrace id",
            enter_height: "Enter Height",
            enter_weight: "Enter Weight",
            enter_age: "Enter Age",
            enter_waist: "Enter Waist",
            enter_ethnicity: "Enter Ethnicity",
            enter_neck: "Enter Neck",

        },
        messages: {
            email__msg: "Please Enter Email",
            title__msg: "Please Enter Title!",
            enterSize__msg: "Please Enter size!",
            fabric__msg: "Please select Fabric!",
            password__msg: "Please Enter Password",
            enterPrice__msg: "Please Enter price!",
            lastName__msg: "Please Enter Last Name",
            category__msg: "Please select Category!",
            access__msg: "Please Select atleast one",
            firstName__msg: "Please Enter First Name",
            enterVersion__msg: "Please Enter version!",
            description__msg: "Please Enter Description!",
            company_name: "Please Enter Company name",
            contact_name: "Please Enter Contact name",
            add_line1: "Please Enter Address line 1",
            select_country: "Please Select Country",
            select_state: "Please Select State",
            city: "Please Enter city",
            zip_Code: "Please Enter Zip code",
            company_email: "Please Enter Company email",
            contact_phone: "Please Enter Contact phone",
            tax_id: "Please Enter Tax ID",
            card_name: "Please Enter Name on card",
            card_num: "Please Enter Card number",
            ex_month: "Please Enter Expiration month",
            ex_year: "Please Enter Expiration year",
            contact_validation: "Please enter a maximum of 10 digits",
            subCategory__msg: "Please select SubCategory!",
            password__conf__msg: "Please confirm Password",
            min__user__msg: "Atlease single user is required",
            retailPrice__msg: "Please enter the retail price!",
            enterProductCode_msg: "Please Enter Product Code!",
            prodVarients__msg: "Please select Product variants!",
            accept__tc__msg: "Please accept our Terms & Conditions",
            password__minLen__msg: "Minumun length must be 8 digit",
            notification__type__msg: "Notification type is required",
            delete__conf__msg: "Are you sure you want to delete this",
            notification__title__msg: "Notification title is required",
            store_url: "Please enter your store URl",
            validate_stor_url: "Please enter a valid store URL in the format storename.myshopify.com",
            notification__desc__msg: "Notification description is required",
            tax_reg_num: "Please Enter tax registration number",
            password__notMatch__msg: "The new password that you entered do not match!",
            password__speChar__msg: "Password must contain at least one special character",
            saveProd__msg: "Product variants cannot be changed once the product is saved. Are you sure you want to continue?",
            Paytrace_id: "Please Enter Paytrace id",

            variantsAreNotAvailable: "Variants are not available"
        },
        buttons: {
            no__btn: "No",
            yes__btn: "Yes",
            save__btn: "Save",
            clear__btn: "Clear",
            login__btn: "Sign in",
            submit__btn: "Submit",
            update__btn: "Update",
            cancel__btn: "Cancel",
            delete__btn: "Delete",
            sendNew__btn: "Send New",
            cancel__order__btn: "Cancel order",
            update__btn_order: "Edit order",
            back__sign__btn: "Back to sign in",
            account__btn: "Create Your account",
            sendNotification__btn: "Send Notification",
            addStore: "Add  store"
        },
        policies: {
            about: "About",
            disclaimer: "Disclaimer",
            privacy: "Privacy Policy",
            terms: "Terms & Conditions",
        },
        table: {
            id: "ID",
            add: "Add",
            type: "Type",
            edit: "Edit",
            view: "View",
            title: "Title",
            profit: "Profit",
            images: "Images",
            answer: "Answer",
            addNew: "Add New",
            question: "Question",
            costPrice: "Cost Price",
            createdAt: "Created At",
            usersList: "Users List",
            approximate: "Approximate",
            addToOrder: "Add to Order",
            retailPrice: "Retail Price",
            description: "Description",
            selectUsers: "Select Users",
            notiType: "Notification Type",
            variantOptions: "Variant options",
            sendNewNoti: "Send New Notification",
            addToCartMsg: "Are you sure you want to add these variants to cart",

        },

        common__content: {
            faq: "FAQ",
            next: "Next",
            cart: "Cart",
            users: "Users",
            media: "Media",
            global: "Global",
            logout: "Logout",
            loading: "Loading",
            profile: "Profile",
            setting: "Settings",
            previous: "Previous",
            wait: "Please wait!",
            language: "Language",
            my__profile: "My Profile",
            browseFiles: "Browse Files",
            notification: "Notification",
            selectSize: "Select size (s)",
            please__check: "Please Check!",
            activationPending: "Activation Pending",
            enable__wait__list: "Enable waiting list",
            internet__lost: "Internet Connectivity Lost",
            drapDropFile: "Drag and drop your file to upload ",
            deleteTitle: "Are you sure, you want to delete this",
            placeOrderAlert: "Would you like to submit the order?",
            placeOrderPara: "Please note that if you choose 'With Delay', your order will be submitted to the MWW system and you will not be able to Edit/Cancel the order.",

            approve: "Approve",
            onlineProofApproval: "Online proof approval",
            copyrightAuthorization: "Copyright authorization",
            iConfirmThat: "I confirm that I am the copyright or license owner of an uploaded image.",
            byClicking: "By clicking the approve button, I agree that spelling, content and layout are correct. I understand that my document will print exactly as it appears above and that I cannot make any changes once my order has been placed.",
        },
        sideList: {
            logs: "Logs",
            store: "Stores",
            order: "Orders",
            catalog: "Catalog",
            gallery: "Gallery",
            setting: "Settings",
            customer: "Customers",
            subAdmin: 'Sub Admin',
            prod__lib: "Product Library",
            notification: "Notification",
            izeUSA: "Recommended Size",
        },
        gallery: {
            photos: "Photos",
            videos: "Videos",
            supportedFormat: "Supported format",
            unSupportedFormat: "Unsupported file format",
        },
        catelog: {
            size: "Size",
            A2Z: "A to Z",
            Z2A: "Z to A",
            price: "Price",
            catSort: "Sort",
            prod: "Product",
            search: "Search",
            filter: "Filter",
            H2L: "High to Low",
            L2H: "Low to High",
            catalog: "Catalog",
            process: "Process",
            actions: "Actions",
            version: "Version",
            category: "Category",
            variants: "Variants",
            features: "Features",
            addPhoto: "Add photo",
            templates: "Templates",
            materials: "Materials",
            prodCode: "Product Code",
            addProduct: "Add Product",
            sortByCost: "Sort by cost",
            subCategory: "Subcategory",
            sortByName: "Sort by name",
            startDesign: "Start Design",
            sizeUsa: "Recommended Size",
            addCategory: "Add category",
            construction: "Construction",
            careInst: "Care Instructions",
            selectFabric: "Select Fabric",
            priceStart: "Price Starts at",
            addSizeChart: "Add Size Chart",
            addToLibrary: "Add To Library",
            prodDetails: " Product details",
            careCleaning: "Care & Cleaning",
            pricesStartAt: "Prices start at",
            addSubcategory: "Add Subcategory",
            productionTime: "Production Time",
            addProdImage: "Add Product Image",
            productVarients: "Product Varients",
            searchSubcategory: "Search Subcategory",
            noCategoryFound: "No Category Found...",
            noSubcategoryFound: "No SubCategory Found...",

        },
        customers: {
            total: "Total",
            status: "Status",
            asc: "Ascending",
            action: "Action",
            active: "Active",
            activeCustomers: "Active Customers",
            desc: "Descending",
            pending: "Pending",
            deactivated: "Deactivated",
            addCustomer: "Add Customer",
            editCustomer: "Edit Customer",
            customerID: "Customer ID",
            pendingActivation: "Pending Activation",
            companyName: "Company Name",
            contactName: "Contact Name",
            ofOrders: "of orders",
            createdDate: "Created Date",
            accountStatus: "Account Status",

            activateCustomer: "Activate customer",
            deactivateCustomer: "Deactivate customer",
            enterApiKey: "Please Enter API Key",
            apiKeyLabel: "Please enter the API key to activate the customer",
        },
        subAdmin: {
            read: 'Read',
            write: 'Write',
            addSubAdmin: "Add Sub Admin",
            editSubAdmin: "Edit Sub Admin",
            totalSubAdmin: "Total Sub Admin",
        },
        productLibrary: {
            clearAll: "Clear All",
            duplicate: "Duplicate",
            selectAll: "Select All",
            storeList: "Store list",
            editDesign: "Edit Design",
            editProd: "Edit Product",
            addToStore: "Add to Store",
            createProd: "Create Product",
            serachProd: "Search Product",
            addedStore: "Added to Store",
            chooseStore: "Choose a store",
            importProducts: "Import products",
            recCreated: "Recently Created",
            noStoreFound: "No store found.",
            recModified: "Recently modified",
            notAddedStore: "Not Added to Store",
            priceStartsFrom: "Price starts from",
            viewUploadStatus: "View upload Status",
        },
        order: {
            s: "s",
            size: "Size",
            IOSS: "IOSS",
            phone: "Phone",
            quantity: "Quantity",
            testOrder: "Test order",
            liveOrder: "Live order",
            yourOrder: "Your order",
            itemPrice: "Item price",
            placeOrder: "Place order",
            updateOrder: "Update order",
            orderAmount: "Order amount",
            editAddress: "Edit address",
            emailAddress: "Email Address",
            shippingMethod: "Shipping method",
            billingInfo: "Billing information",
            noProductsFound: "No Products found",
            shippingInfo: "Shipping information",
            addMoreProducts: "Add more products",
            customPackingSlips: "Custom packing slips",
            shippingAccNum: "Shipping account number",
            customPreshiplabels: "Custom preship labels",
            cartCheckText: "Billing information is same as shipping information",
            orderAlert: "Please fill IOSS, HS code and Declared value fields only if the destination country is EU or UK",
            customerAlert: "You must enter your card number before updating any other payment related information",

            browseFile: 'Browse file',
            close: 'Close',
            orderItems: 'Order items',
            orders: 'Orders',
            quantity: 'Quantity',
            orderInfo: 'Order information',
            shippingAddress: 'Shipping address',
            billingAddress: 'Billing address',
            trackingNumber: 'Tracking number',
            shippingAccountNumber: 'Shipping Account Number',
            orderOverview: 'Orders overview',
            orderVarients: 'Ordered variants',
            orderDetails: 'Order Details',
            all: 'All',
            apply: 'Apply',
            reset: 'Reset',
            stores: 'Stores',
            customerName: 'Customer name',
            orderAmount: 'Order Amount',
            orderDate: 'Order Date',
            storeName: 'Store Name',
            orderStatus: 'Order Status',
            orderID: 'Order ID',
            excelUpload: 'Excel upload',
            newOrder: 'New Order',
            merchMaker: 'Merch maker',
            importOrder: 'Import Order',
            drapDropExcel: 'Drag and drop your excel file here or',
            selectStores: 'Select Stores',
            days: 'Days',
            days: 'Days',
            hours: 'Hours',
            minutes: 'Minutes',
            error: 'Error',
            cancelled: 'Cancelled',
            orderType: 'Order Type',
            orderSubmissionDelay: 'Order Submission delay',
            shipped: 'Shipped',
            download: 'Download',
            inProduction: 'In Production',
            totalOrders: 'Total orders',
            orderOrangeText: 'The orders in orange text are test orders',
            refersToOrder: 'refers to the orders imported via csv file',
            downloadSampleTemplates: 'Download sample templates',
            cancelOrderAlert: 'Are you sure you want to cancel the order',
            orderDelayTitle: 'All your new orders would be submitted to the MWW system after the time frame set below.',
        },
        profile: {
            back: "Back",
            pending: "Pending",
            complete: "Complete",
            basicInfo: "Basic Info",
            personalInfo: "Personal Info",
            paymentInfo: "Payment Info",
            billingInfo: "Billing Info",
            shippingInfo: "Shipping Info",
            basicInfor: "Basic information",
            creditCardAddress: "Credit card address",
            accountActivationAlert: "To get your account activated, Please complete your Profile.",
        },
        store: {
            desc: "You have not connected a store yet.",
            connect_shopify: "Connect shopify",
            shopify: "Shopify",
            connect_shop_dec: "Connect to the Shopify and display your products with one click.",
            connect: "Connect",
            connect_title: "Add your store URL to connect <br/> with MWW",
            Store_URL: "Store URL",
            placeOrder: "e.g. my-shop.myshopify.com",
            contact: "Please only input the storename.myshopify.com. Do not include https:// or any other symbols after .com<",
            delete_store: "Are you sure you want to remove this store?",
            disconnect_store: "Are you sure you want to disconnect this store?",
            detete_warm: "All data related to this store will be lost and this action cannot be reversed.",
            remove: "Remove",
            disconnect: "Disconnect",
            store_URL: "Store URL",

            date: "Date",
            retry: "Retry",
            store: "Store",
            stores: "Stores",
            failed: "Failed",

            ascending: "Ascending",
            descending: "Descending",

            completed: "Completed",
            processing: "Processing",
            uploadDate: "Upload Date",
            productName: "Product Name",
            storeUploads: "Store Uploads",
            totalUploads: "Total Uploads",
            uploadStatus: "Upload Status",

        },
        faq: {
            subject: "Subject",
            raiseTicket: "Raise Ticket",
            howCanWeHelp: "How can we help",
            raiseATicket: "Raise a Ticket",
            subjectMsg: "Please enter Subject",
            answerInFollowing: "Answers in following categories",
        },
    },

    spanish: {
        auth: {
            online: "en línea",
            sign__in: "Iniciar sesión",
            change__pass: "Cambiar contraseña",
            create__account: "Crea tu cuenta",
            forget__password: "Olvidar contraseña",
            not_account: "¿No tienes una cuenta?",
            reset__password: "Restablecer contraseña",
            create__new__account: "Crear nueva cuenta",
            create__new__account: "Crear nueva cuenta",
            already__account: "¿Ya tienes una cuenta?",
            image__title: "Crea y vende productos personalizados",
            sign__terms: "Entiendo que MWW On Demand es un servicio de negocio a negocio, y acepto los",
            reset__title: "Ingresa tu dirección de correo electrónico. Te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña.",
        },
        auth__header: {
            home: "Inicio",
            blog: "Blog de la empresa",
            what__make: "Lo que hacemos",
            how__works: "Cómo funciona",
        },
        lables: {
            access: "Acceso",
            firstName: "Nombre",
            lastName: "Apellido",
            password: "Contraseña",
            email: "Correo electrónico",
            new__password: "Nueva Contraseña",
            old__password: "Contraseña anterior",
            conf__password: "Confirmar Contraseña",
            password__conf: "Confirmación de contraseña",
            company_name: "Nombre de la empresa",
            contact_name: "Nombre de contacto",
            add_line1: "Dirección línea 1",
            add_line2: "Dirección línea 2",
            country: "País",
            state: "Estado",
            city: "Ciudad",
            zip_Code: "Código postal",
            company_email: "Correo electrónico de la empresa",
            contact_phone: "Teléfono de contacto",
            tax_id: "Identificación fiscal",
            tax_reg_num: "Número de registro de ventas e impuestos de Carolina del Norte",
            card_name: "Nombre en la tarjeta",
            card_num: "Número de tarjeta",
            ex_month: "Mes de vencimiento",
            ex_year: "Año de vencimiento",
            Paytrace_id: "Identificación de Paytrace",
            or: "O"


        },
        placeholders: {
            email: "Correo electrónico",
            firstName: "Ingresa el nombre",
            lastName: "Ingresa el apellido",
            password: "Ingresa la contraseña",
            password__conf: "Confirmación de contraseña",
            company_name: "Ingrese el nombre de la empresa",
            contact_name: "Ingrese el nombre de contacto",
            add_line1: "Ingrese la dirección línea 1",
            add_line2: "Ingrese la dirección línea 2 (Opcional)",
            zip: "Ingrese el código postal",
            contact_phone: "Ingrese el teléfono de contacto",
            tax_id: "Ingrese el ID fiscal",
            optional: "Opcional",
            enterValue: "Ingresar valor",
            enterProcess: "Ingresar el proceso",
            selectCategory: "Seleccionar categoría",
            enterFeatures: "Ingresar las características",
            selectSubcategory: "Seleccionar subcategoría",
            enterDescription: "Ingresar la descripción",
            enterProdTime: "Ingresar el tiempo de producción",
            enterConstruction: "Ingresar la construcción",
            enterCareInst: "Ingresar las instrucciones de cuidado",
            selectProdVarients: "Ingresar las variantes del producto",
            tax_reg_num: "Ingrese el número de registro fiscal.",
            ex_month: "Ingresar mes de vencimiento",
            ex_year: "Ingresar año de vencimiento",
            select_country: "Seleccionar País",
            select_state: "Seleccionar Estado",
            Paytrace_id: "Ingrese el ID de Paytrace",

        },
        buttons: {
            no__btn: "No",
            yes__btn: "Sí",
            save__btn: "Guardar",
            clear__btn: "Limpiar",
            submit__btn: "Enviar",
            cancel__btn: "Cancelar",
            delete__btn: "Eliminar",
            update__btn: "Actualizar",
            login__btn: "Iniciar sesión",
            sendNew__btn: "Enviar Nuevo",
            account__btn: "Crear tu cuenta",
            cancel__order__btn: "Cancelar pedido",
            back__sign__btn: "Volver a iniciar sesión",
            sendNotification__btn: "Enviar Notificación",
            addStore: "Agregar tienda",
        },
        messages: {
            title__msg: "¡Por favor, ingresa un título!",
            fabric__msg: "¡Por favor, selecciona una tela!",
            firstName__msg: "Por favor, ingrese su nombre",
            lastName__msg: "Por favor, ingrese su apellido",
            enterSize__msg: "¡Por favor, ingresa el tamaño!",
            enterPrice__msg: "¡Por favor, ingresa el precio!",
            access__msg: "Por favor, selecciona al menos uno",
            password__msg: "Por favor, ingrese su contraseña",
            min__user__msg: "Se requiere al menos un usuario",
            enterVersion__msg: "¡Por favor, ingresa la versión!",
            email__msg: "Por favor, ingrese su correo electrónico",
            category__msg: "¡Por favor, selecciona una categoría!",
            description__msg: "¡Por favor, ingresa una descripción!",
            company_name: "Por favor, ingrese el nombre de la empresa",
            contact_name: "Por favor, ingrese el nombre de contacto",
            add_line1: "Por favor, ingrese la dirección línea 1",
            select_country: "Por favor, seleccione el país",
            select_state: "Por favor, seleccione el estado",
            city: "Por favor, ingrese la ciudad",
            zip_Code: "Por favor, ingrese el código postal",
            company_email: "Por favor, ingrese el correo electrónico de la empresa",
            variantsAreNotAvailable: "Las variantes no están disponibles",
            contact_phone: "Por favor, ingrese el teléfono de contacto",
            tax_id: "Por favor, ingrese el ID fiscal",
            card_name: "Por favor, ingrese el nombre en la tarjeta",
            store_url: "Por favor, ingresa la URL de tu tienda",
            validate_stor_url: "Por favor, ingresa una URL de tienda válida en el formato storename.myshopify.com",

            card_num: "Por favor, ingrese el número de tarjeta",
            ex_month: "Por favor, ingrese el mes de vencimiento",
            ex_year: "Por favor, ingrese el año de vencimiento",
            contact_validation: "Por favor, ingrese un máximo de 10 dígitos",
            tax_reg_num: "Por favor, ingrese el número de registro fiscal.",
            password__conf__msg: "Por favor, confirme su contraseña",
            subCategory__msg: "¡Por favor, selecciona una subcategoría!",
            notification__type__msg: "Se requiere el tipo de notificación",
            delete__conf__msg: "¿Estás seguro de que deseas eliminar esto?",
            enterProductCode_msg: "¡Por favor, ingresa el Código de Producto!",
            prodVarients__msg: "¡Por favor, selecciona variantes del producto!",
            accept__tc__msg: "Por favor, acepte nuestros Términos y Condiciones",
            notification__title__msg: "Se requiere el título de la notificación",
            password__minLen__msg: "La contraseña debe tener al menos 8 dígitos",
            retailPrice__msg: "Por favor, ingrese el precio de venta al público",
            notification__desc__msg: "Se requiere la descripción de la notificación",
            password__notMatch__msg: "¡La nueva contraseña que ingresaste no coincide!",
            password__speChar__msg: "La contraseña debe contener al menos un carácter especial",
            saveProd__msg: "Las variantes del producto no se pueden cambiar una vez que el producto se guarda. ¿Estás seguro de que quieres continuar?",
            Paytrace_id: "Por favor ingrese el ID de Paytrace",

        },
        policies: {
            about: "Acerca de",
            disclaimer: "Aviso legal",
            terms: "Términos y condiciones",
            privacy: "Política de Privacidad",
        },
        table: {
            id: "ID",
            view: "Ver",
            type: "Tipo",
            add: "Agregar",
            edit: "Editar",
            title: "Título",
            images: "Imágenes",
            answer: "Respuesta",
            profit: "Beneficio",
            question: "Pregunta",
            createdAt: "Creado el",
            addNew: "Agregar Nuevo",
            approximate: "Aproximado",
            description: "Descripción",
            costPrice: "Precio de costo",
            usersList: "Lista de Usuarios",
            addToOrder: "Agregar al pedido",
            notiType: "Tipo de Notificación",
            selectUsers: "Seleccionar Usuarios",
            variantOptions: "Opciones de variante",
            sendNewNoti: "Enviar Nueva Notificación",
            retailPrice: "Precio de venta al público",
            addToCartMsg: "¿Estás seguro de que quieres añadir estas variantes al carrito?",
        },
        common__content: {
            cart: "Carrito",
            media: "Medios",
            global: "Global",
            users: "Usuarios",
            next: "Siguiente",
            profile: "Perfil",
            language: "Idioma",
            loading: "Cargando",
            previous: "Anterior",
            logout: "Cerrar sesión",
            setting: "Configuración",
            my__profile: "Mi Perfil",
            wait: "Por favor, espere",
            faq: "Preguntas frecuentes",
            notification: "Notificaciones",
            browseFiles: "Explorar archivos",
            selectSize: "Seleccionar talla (s)",
            please__check: "¡Por favor, comprueba!",
            activationPending: "Activación pendiente",
            internet__lost: "Conexión a Internet perdida",
            enable__wait__list: "Habilitar lista de espera",
            placeOrderAlert: "¿Te gustaría enviar el pedido",
            deleteTitle: "¿Estás seguro de que deseas eliminar esto?",
            drapDropFile: "Arrastra y suelta tu archivo para cargarlo",
            placeOrderPara: "Tenga en cuenta que si elige 'Sí', su pedido se enviará al sistema MWW y no podrá editar/cancelar el pedido.",
            approve: "Aprobar",
            onlineProofApproval: "Aprobación de prueba en línea",
            copyrightAuthorization: "Autorización de derechos de autor",
            iConfirmThat: "Confirmo que soy el propietario de los derechos de autor o licencia de una imagen cargada.",
            byClicking: "Al hacer clic en el botón de aprobación, acepto que la ortografía, el contenido y el diseño son correctos. Entiendo que mi documento se imprimirá exactamente como aparece arriba y que no puedo realizar cambios una vez que se haya realizado mi pedido.",
        },
        sideList: {
            store: "Tiendas",
            order: "Órdenes",
            logs: "Registros",
            gallery: "Galería",
            catalog: "Catálogo",
            customer: "Clientes",
            setting: "Configuración",
            subAdmin: 'Subadministrador',
            notification: "Notificaciones",
            prod__lib: "Biblioteca de productos",
        },
        gallery: {
            photos: "Fotos",
            videos: "Videos",
            supportedFormat: "Formato admitido",
            unSupportedFormat: "Formato de archivo no admitido",
        },
        catelog: {
            size: "Talla",
            price: "Precio",
            prod: "producto",
            search: "Buscar",
            filter: "Filtrar",
            process: "Proceso",
            version: "Versión",
            catSort: "Ordenar",
            catalog: "Catálogo",
            actions: "Acciones",
            category: "Categoría",
            variants: "Variantes",
            H2L: "De alto a bajo",
            L2H: "De bajo a alto",
            A2Z: "De la A a la Z",
            Z2A: "De la Z a la A",
            materials: "Materiales",
            templates: "Plantillas",
            addPhoto: "Agregar foto",
            features: "Características",
            subCategory: "Subcategoría",
            construction: "Construcción",
            addProduct: "Agregar Producto",
            startDesign: "Comenzar Diseño",
            sortByCost: "Ordenar por costo",
            priceStart: "Precio Empieza en",
            prodCode: "Código del Producto",
            selectFabric: "Seleccionar tela",
            sortByName: "Ordenar por nombre",
            addCategory: "Agregar Categoría",
            careCleaning: "Cuidado y Limpieza",
            careInst: "Instrucciones de Cuidado",
            prodDetails: "Detalles del Producto",
            addSubcategory: "Agregar Subcategoría",
            productionTime: "Tiempo de Producción",
            addToLibrary: "Agregar a la Biblioteca",
            addSizeChart: "Agregar Tabla de Tallas",
            searchSubcategory: "Buscar Subcategoría",
            productVarients: "Variantes del Producto",
            pricesStartAt: "Los precios comienzan en",
            addProdImage: "Agregar Imagen del Producto",
            noCategoryFound: "No se encontró categoría...",
            noSubcategoryFound: "No se encontró subcategoría...",

        },
        customers: {
            total: "Total",
            action: "Acción",
            active: "Activo",
            status: "Estado",
            asc: "Ascendente",
            desc: "Descendente",
            pending: "Pendiente",
            deactivated: "Desactivado",
            addCustomer: "Agregar Cliente",
            editCustomer: "Editar Cliente",
            customerID: "ID del cliente",
            activeCustomers: "Clientes Activos",
            pendingActivation: "Activación Pendiente",
            companyName: "Nombre de la Empresa",
            contactName: "Nombre de Contacto",
            ofOrders: "de pedidos",
            createdDate: "Fecha de Creación",
            accountStatus: "Estado de la Cuenta",

            activateCustomer: "Activar cliente",
            deactivateCustomer: "Desactivar cliente",
            enterApiKey: "Por favor, ingrese la clave API",
            apiKeyLabel: "Por favor, ingrese la clave API para activar al cliente",

        },
        subAdmin: {
            read: 'Leer',
            write: 'Escribir',
            addSubAdmin: 'Agregar Subadministrador',
            editSubAdmin: 'Editar Subadministrador',
            totalSubAdmin: 'Total de Subadministradores',
        },
        productLibrary: {
            clearAll: "Limpiar todo",
            duplicate: "Duplicar",
            selectAll: "Seleccionar todo",
            storeList: "Lista de tiendas",
            editDesign: "Editar diseño",
            editProd: "Editar producto",
            addToStore: "Agregar a tienda",
            createProd: "Crear producto",
            serachProd: "Buscar producto",
            chooseStore: "Elige una tienda",
            importProducts: "importar producto",
            addedStore: "Agregado a tienda",
            recCreated: "Recientemente creado",
            noStoreFound: "No se encontró ninguna tienda.",
            recModified: "Recientemente modificado",
            notAddedStore: "No agregado a tienda",
            priceStartsFrom: "El precio comienza desde",
            viewUploadStatus: "Ver estado de carga",
        },
        order: {
            s: "s",
            size: "Tamaño",
            IOSS: "IOSS",
            phone: "Teléfono",
            quantity: "Cantidad",
            testOrder: "Pedido de prueba",
            liveOrder: "Pedido en vivo",
            yourOrder: "Tu pedido",
            itemPrice: "Precio del artículo",
            placeOrder: "Realizar pedido",
            updateOrder: "Actualizar orden",
            orderAmount: "Importe del pedido",
            editAddress: "Editar dirección",
            emailAddress: "Correo electrónico",
            shippingMethod: "Método de envío",
            billingInfo: "Información de facturación",
            noProductsFound: "No se encontraron productos",
            shippingInfo: "Información de envío",
            addMoreProducts: "Agregar más productos",
            customPackingSlips: "Notas de embalaje personalizadas",
            shippingAccNum: "Número de cuenta de envío",
            customPreshiplabels: "Etiquetas personalizadas antes del envío",
            cartCheckText: "La información de facturación es la misma que la información de envío",
            orderAlert: "Complete los campos de IOSS, código HS y valor declarado solo si el país de destino es la UE o el Reino Unido",
            customerAlert: "Debes ingresar tu número de tarjeta antes de actualizar cualquier otra información relacionada con el pago",
            new: 'Nuevo',
            close: 'Cerrar',
            orders: 'Pedidos',
            quantity: 'Cantidad',
            orderInfo: 'Información del pedido',
            shippingAddress: 'Dirección de envío',
            billingAddress: 'Dirección de facturación',
            trackingNumber: 'Número de seguimiento',
            shippingAccountNumber: 'Número de cuenta de envío',
            customerName: 'Nombre del cliente',
            orderOverview: 'Resumen de pedidos',
            orderVarients: 'Variantes solicitadas',
            orderDetails: 'Detalles del pedido',
            all: 'Todo',
            apply: 'Aplicar',
            orderItems: 'Ítems del pedido',
            reset: 'Restablecer',
            stores: 'Tiendas',
            customerName: 'Nombre del cliente',
            orderAmount: 'Monto del pedido',
            orderDate: 'Fecha del pedido',
            storeName: 'Nombre de la tienda',
            orderStatus: 'Estado del pedido',
            orderID: 'ID del pedido',
            excelUpload: 'Carga de Excel',
            newOrder: 'Nuevo pedido',
            merchMaker: 'Fabricante de mercancía',
            importOrder: 'Importar pedido',
            drapDropExcel: 'Arrastre y suelte su archivo de Excel aquí o',
            selectStores: 'Seleccionar tiendas',
            days: 'Días',
            browseFile: 'Explorar archivo',
            hours: 'Horas',
            minutes: 'Minutos',
            error: 'Error',
            cancelled: 'Cancelado',
            orderType: 'Tipo de pedido',
            orders: 'Pedidos',
            orderSubmissionDelay: 'Demora en la presentación del',
            shipped: 'Enviado',
            download: 'Descargar',
            inProduction: 'En producción',
            totalOrders: 'Total de pedidos',
            orderOrangeText: 'Los pedidos en texto naranja son pedidos de prueba',
            refersToOrder: 'se refiere a los pedidos importados mediante archivo CSV',
            downloadSampleTemplates: 'Descargar plantillas de muestra',
            cancelOrderAlert: '¿Seguro que quieres cancelar el pedido',
            orderDelayTitle: 'Todos tus nuevos pedidos se enviarán al sistema MWW después del plazo establecido a continuación.',
        },
        profile: {
            back: "Volver",
            pending: "Pendiente",
            complete: "Completo",
            basicInfo: "Información Básica",
            paymentInfo: "Información de Pago",
            billingInfo: "Información de Facturación",
            shippingInfo: "Información de Envío",
            basicInfor: "Información Básica",
            creditCardAddress: "Dirección de Tarjeta de Crédito",
            accountActivationAlert: "Para activar tu cuenta, por favor completa tu perfil.",
        },
        store: {
            desc: "Aún no has conectado una tienda.",
            connect_shopify: "Conectar con Shopify",
            shopify: "Shopify",
            connect_shop_dec: "Conéctate a Shopify y muestra tus productos con un solo clic.",
            connect: "Conectar",
            connect_title: "Agrega la URL de tu tienda para conectarte <br/> con MWW",
            Store_URL: "URL de la tienda",
            placeOrder: "por ejemplo, my-shop.myshopify.com",
            contact: "Por favor, solo ingresa el nombre de la storename.myshopify.com. No incluyas https:// ni ningún otro símbolo después de .com",
            delete_store: "¿Estás seguro de que deseas eliminar esta tienda?",
            detete_warm: "Todos los datos relacionados con esta tienda se perderán y esta acción no se puede revertir.",
            remove: "Eliminar",
            store_URL: "URL de la tienda",

            date: "Fecha",
            store: "Tienda",
            stores: "Tiendas",
            failed: "Fallido",
            retry: "Reintentar",
            completed: "Completado",
            ascending: "Ascendente",
            processing: "Procesando",
            descending: "Descendente",
            uploadDate: "Fecha de carga",
            totalUploads: "Cargas totales",
            uploadStatus: "Estado de carga",
            storeUploads: "Cargas de tienda",
            productName: "Nombre del producto",
        },
        faq: {
            subject: "Asunto",
            raiseTicket: "Abrir un ticket",
            howCanWeHelp: "¿Cómo podemos ayudarte?",
            raiseATicket: "Abrir un ticket",
            subjectMsg: "Por favor, introduce el asunto",
            answerInFollowing: "Respuestas en las siguientes categorías",
        },

    }

}

export default languages;