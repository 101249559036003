import { Suspense, lazy, useEffect, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import CustomLoader from "./components/CustomLoader";

import { persistor, store } from "./store";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

import { firebaseApiKey } from "./Constant/Url";
import { getToken, onMessage } from "firebase/messaging";
import messaging, { onMessageListener } from "./firebaseConfig";
import Toast from "./Constant/Toast";
import NetConnection from "./components/NetConnection";

const App = () => {
  const AppRouter = lazy(() => import("./Routes"));
  const [netModal, setNetModal] = useState(false);

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Generating FCM Token
      const token = await getToken(messaging, { vapidKey: firebaseApiKey })
    } else if (permission === 'denied') {
      // alert('Notification Permission denied by user...! ')
    } else {
      console.log("Something went wrong!", permission)
    };
  };

  useEffect(() => {
    requestNotificationPermission()

    // Handle incoming messages
    onMessageListener((noti) => {
      // console.log("Noti", noti.notification);
      Toast("success", <div><b><i>Notification</i> : </b><br /> {noti?.notification?.title} <br /> {noti?.notification?.body}</div>)
    });
    
    /* Offline Alert */
    // if (!navigator.onLine) {
    //   setNetModal(true);
    // } else {
    //   setNetModal(false)
    // }
  }, [])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<CustomLoader />}>
          <ErrorBoundary>
            <AppRouter />
          </ErrorBoundary>
        </Suspense>
      </PersistGate>

      {/* Internet Connection Alert */}
      {netModal && <NetConnection netModal={netModal} setNetModal={setNetModal} />}
    </Provider>
  );
}

export default App;
